
import { ref, computed } from "vue";
import { useRoot } from "@libraryHelpers/compositionApi";
import { useIsExpired } from "@libraryComposables/useIsExpired";

export default {
	name: "CoreBlockButton",
	props: {
		settings: {
			type: Object,
			required: true,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const root = useRoot();
		const isDialogOpen = ref(false);

		const itemCheckFunction = (item) =>
			item.type === "document" || item.type === "link";

		const { isExpired } = useIsExpired(props.settings, itemCheckFunction);

		const buttonLabel = computed(() => {
			return props.settings?.label || "Button Label";
		});

		const buttonAltText = computed(() => {
			return props.settings?.alt || buttonLabel.value;
		});

		const buttonUrl = computed(() => {
			if (isExpired.value) return null;
			const url = props.settings?.url;
			if (
				typeof url === "string" &&
				url?.includes("https://calendly.com")
			) {
				return null;
			} else if (url === "dialog") {
				return null;
			}

			const newUrl = props.settings?.newWindow
				? replaceUrlBasePath(url)
				: url;
			return newUrl || null;
		});

		const clickEvent = computed(() => {
			if (
				typeof props.settings?.url === "string" &&
				props.settings?.url?.includes("https://calendly.com")
			) {
				return showPopUp;
			} else if (isDialog.value) {
				return toggleDialog;
			}
			return props.settings?.event || noEventFound;
		});

		const isInternal = computed(() => {
			const url = props.settings?.url;
			return (
				(typeof url === "string" && url?.charAt(0) == "/") ||
				(typeof url === "object" && url !== null)
			);
		});

		const isDialog = computed(() => {
			return props.settings?.url === "dialog";
		});

		const newWindowTarget = computed(() => {
			if (props.settings?.newWindow) return "_blank";
			return "_self";
		});

		const newWindowAriaLabel = computed(() => {
			if (props.settings.newWindow)
				return `${buttonLabel.value} (opens in a new window)`;
			return null;
		});

		const buttonDataTestId = computed(() => {
			const calendlyUrl =
				typeof props.settings?.url === "string" &&
				props.settings?.url?.includes("https://calendly.com");
			return isDialog.value || calendlyUrl ? "dialog-button" : "button";
		});

		const formSettings = computed(() => {
			return props.settings?.dialog || {};
		});

		const noEventFound = () => {};

		const focusPopup = () => {
			// Calendly uses an iframe, it can't be manipulated because of
			// same-origin browser's policy.
			const container = document.querySelector(".calendly-popup-content");
			if (!container) {
				return;
			}
			const button = document.createElement("button");
			container.prepend(button);
			button.focus();
			button.remove();
		};

		const showPopUp = () => {
			GTMCalendly();
			if (process.client) {
				window.Calendly.showPopupWidget(props.settings?.url);
				setCalendlyAttributes(props.settings.iframeTitle);
				addModalListener();
				focusPopup();
			}
			return false;
		};

		const toggleDialog = () => {
			isDialogOpen.value = !isDialogOpen.value;
			return false;
		};

		const setCalendlyAttributes = (title) => {
			const iframes = document.querySelectorAll("iframe");
			for (const iframe of iframes) {
				if (iframe.src?.includes("https://calendly.com")) {
					iframe.setAttribute(
						"title",
						title || "default-calendly-title"
					);
				}
			}
			document
				.querySelector(".calendly-popup-close")
				.setAttribute("tabIndex", "0");
		};

		const closeModalKeyHandler = (event) => {
			if (event.key === "Escape" || event.keyCode === 27) {
				closeCalendlyModal();
			}
		};

		const closeCalendlyModal = () => {
			window.Calendly.closePopupWidget();
			document.removeEventListener("keydown", closeModalKeyHandler, true);
		};

		const addModalListener = () => {
			document.addEventListener("keydown", closeModalKeyHandler, true);
		};

		const GTMCalendly = () => {
			const { eventType, calendlyId } = decodeCalendlyUrl(
				props.settings.url
			);
			root.$gtm.push({
				event: "nyl-calendly",
				calendly_id: calendlyId,
				calendly_category: getCalendlyCategory(eventType),
				event_type: eventType,
				calendly_event_id: eventType?.toLowerCase().replace(/\s/g, ""),
				click_url: props.settings.url,
			});
		};

		const getCalendlyCategory = (eventType) => {
			var calendlyCategory = "";
			switch (eventType) {
				case "Custom Event":
					calendlyCategory = "custom";
					break;
				case "General Discussion":
				case "Intro Appointment":
				case "Review":
					calendlyCategory = "agent";
					break;
				case "Intro to NYL":
				case "Quick Checkin":
				case "First Interview":
					calendlyCategory = "GO";
					break;
			}
			return calendlyCategory;
		};

		const replaceUrlBasePath = (url) => {
			if (!url || !isInternal.value) return url;

			const basePath = root?.$config?.["_app"]?.basePath ?? "/";
			return `${basePath}/${url}`.replace(/\/+/g, "/");
		};

		const decodeCalendlyUrl = (url) => {
			if (!url || typeof url !== "string" || url.trim() === "") {
				return {
					eventType: null,
					calendlyId: null,
				};
			}

			try {
				const urlObj = new URL(url);
				const pathParts = urlObj.pathname
					.split("/")
					.filter((part) => part !== "");
				const calendlyId = pathParts[0] || null;
				let eventType = "";

				if (pathParts.length > 1) {
					eventType = decodeURIComponent(pathParts[1]);
				}

				if (
					!eventType ||
					(eventType.includes("utm_campaign") &&
						eventType.includes("utm_source"))
				) {
					eventType = null;
				}

				return {
					eventType,
					calendlyId,
				};
			} catch (error) {
				console.error("Invalid Calendly URL:", error);
				return {
					eventType: null,
					calendlyId: null,
				};
			}
		};

		return {
			addModalListener,
			buttonAltText,
			buttonDataTestId,
			buttonLabel,
			buttonUrl,
			clickEvent,
			closeCalendlyModal,
			closeModalKeyHandler,
			formSettings,
			getCalendlyCategory,
			GTMCalendly,
			isDialog,
			isDialogOpen,
			isExpired,
			isInternal,
			newWindowAriaLabel,
			newWindowTarget,
			noEventFound,
			replaceUrlBasePath,
			setCalendlyAttributes,
			showPopUp,
			toggleDialog,
		};
	},
};
